export default {
    data() {
        return {
            flag: false,
            flag1: false,
            flag2: false,
            flag3: false,
            flag4: false,
            flag5: false,
            obj: {},
            tempData: {}
        }
    },
    methods: {
        async getDetails(data) {
            const resp = await this.$apis.formServe.articlePreview(data);
            if (resp.resultCode === '00010106') {
                this.$router.push('/invalid')
                return;
            }
            this.detailsData = resp.data.data;
            this.tempData = resp.data;
        },
        handleUnit(item) {
            if(item.authorUnitStatus !== 1) return
            this.flag1 = true;
            this.obj = item;
        },
        ok(id) {
            window.open(
                `${this.$global.testUrl}recommend?id=${id}`,
            );
        },
        ok1() {
            window.open(
                `${this.$global.testUrl}recommend?id=${this.obj.authorUnitId}`,
            );
        },
        ok2(id) {
            window.open(
                `${this.$global.testUrl}recommend?id=${id}`,
            );
        },
        ok3(id) {
            window.open(
                `https://www.baikezaixian.com/details?ids=${id}`,
            );
        },
        goAuther(type, item) {
            let url;
            if (type === "first") {
                url = this.$router.resolve({
                    path: "/autherdetail",
                    query: {
                        id: this.detailsData.firstAuthorId,
                    },
                });
            } else if (type === "other") {
                url = this.$router.resolve({
                    path: "/autherdetail",
                    query: {
                        id: item.authorId,
                    },
                });
            } else if (type === "communication") {
                url = this.$router.resolve({
                    path: "/autherdetail",
                    query: {
                        id: this.detailsData.communicationAuthorId,
                    },
                });
            }
            window.open(url.href, "_blank");
        },
    }
}